import React, {FunctionComponent, useEffect, useState} from "react"
import {DealVM} from "../selector/dealsList.selector"
import { Helmet } from "react-helmet"
import {SvgIcons, Icons} from "../components/svg/icons"
import { LazyLoadComponent } from 'react-lazy-load-image-component';


const DealPicturePlaceHolder = () => (
    <div className="w-full h-96 bg-gray-200 text-center pt-28" />
)
  
const DealCard: FunctionComponent<{deal: DealVM, lazyload: boolean}> = ({deal, lazyload = true}) => (
      <div id={deal.key} className={'rounded-md bg-white border border-gray-300 mt-2 md:mt-10 ' + (deal.expired ? 'grayscale-1' : '')} >
          <div className="w-full">
            <div className="text-xl mt-2 px-4 uppercase"> <a href={'#'+deal.key}> {deal.hotelName}  </a> </div>
            <div className="text-sm px-4 text-gray-600"> {deal.hotelDescription} </div>
          </div>
          <div className="bg-cover bg-center w-full pt-2 mt-2 relative">

              { lazyload ? (
                    <LazyLoadComponent placeholder={<DealPicturePlaceHolder/>}>
                        <figure className="stinson"> <img className="object-cover w-full h-44 md:h-96 bg-gray-200" src={deal.hotelPicture} /> </figure>
                    </LazyLoadComponent>
              ) : (
                    <figure className="stinson"> <img className="object-cover w-full h-44 md:h-96 bg-gray-200" src={deal.hotelPicture} /> </figure>
              )}
              
              { deal.expired && (<div className="absolute top-2 right-2">
                  <div
                    className="ml-4 mt-5 h-5 text-xs inline-flex items-center px-3 py-1 bg-gray-900 text-gray-200 rounded-full"
                  >
                    cette offre est expirée
                  </div>
                </div>)
            }
          </div>
          <div className="w-full min-h-20 bg-brand-500 hover:bg-brand-600 cursor-pointer px-4 pt-2 text-left sm:text-right transition duration-500 pb-4">
              <div className="font-sans font-bold text-md md:text-xl tracking-wider text-white"> {deal.title} </div>
              <div className="font-sans text-sm text-white"> {deal.subtitle} </div>
          </div>
          <div className="mt-2 px-4">
          <div className="text-gray-800 text-md md:text-lg flex items-center"> <div className="mr-1 w-6 text-gray-800 inline-block h-6"><SvgIcons icon={Icons.CalendarSvg}/></div> <span className="pt-1"> {deal.dateDescription} </span></div>
          </div>
          <div className="w-full px-4 pt-2">
              <div className="text-gray-800 text-sm md:text-md" dangerouslySetInnerHTML={{__html: deal.descriptionHTML}} />
          </div>
          <div className="px-4 pt-2">
              { deal.contactPhone && (<div className="flex items-center cursor-pointer hover:text-brand-500  text-gray-700 "> <div className="w-4 inline-block h-4 mr-2"><SvgIcons icon={Icons.PhoneSvg}/></div> <a href={'tel:'+deal.contactPhone} className="text-sm"> {deal.contactPhone} </a>  </div>) }
              { deal.contactEmail && (<div className="flex items-center cursor-pointer hover:text-brand-500  text-gray-700 "> <div className="w-4 inline-block h-4 mr-2"><SvgIcons icon={Icons.MailSvg}/></div> <a href={'maito:'+deal.contactEmail} className="text-sm"> {deal.contactEmail} </a>  </div>) }
              { deal.contactWebsite && (<div className="flex items-center cursor-pointer hover:text-brand-500 text-gray-700"> <div className="w-4 inline-block h-4 mr-2"><SvgIcons icon={Icons.GlobeSvg}/></div> <a href={deal.contactWebsiteUrlWithProtocol} target="_blank" className=" text-sm"> {deal.contactWebsiteUrlWithoutProtocol} </a>  </div>) }
          </div>
          <div className="mt-4 px-4 flex flex-row items-center pb-2 w-full text-right">
              <div className="text-gray-400 text-xs ml-1 w-full cursor-pointer hover:text-brand-500"> sous réserve de disponibilités - <a href={deal.offerLink} target="_blank"> <span> voir le post original sur Facebook </span> <div className="ml-1 pt-1 w-4 inline-block h-4"> <SvgIcons icon={Icons.ExternalLinkSvg}/> </div>  </a>  </div>
          </div>
      </div>
)

const Popup: FunctionComponent<any> = () => {

    const [toggled, toggle] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            toggle(true)
        }, 30000)
    }, [])

    return (
        <div className={"h-screen w-full bg-black z-20 fixed top-0 left-0 bg-opacity-50 px-5 pt-20 text-center " + (toggled ? 'visible' : 'hidden')}>
            <div className="mx-auto w-full max-w-sm bg-gray-100 rounded-lg shadow-lg p-4 text-center">
                    <div className="w-8 text-brand-400 mx-auto"> <SvgIcons icon={Icons.BellSvg} /> </div>
                    <div className="text-left w-full mb-4"> N'oubliez pas de suivre <span className="text-brand-400 font-bold">iDeal Hôtel </span> sur facebook pour recevoir les meilleurs bon plans en exclusivité  😉 !</div>
                    <div className="fb-page" 
                            data-href="https://www.facebook.com/iDealHotel974"
                            data-width="380" 
                            data-hide-cover="false"
                            data-show-facepile="false"
                    />
            </div>
            <button onClick={() => toggle(false)} className="mx-auto mt-10 bg-gray-200 hover:bg-gray-300 border border-gray-400 text-gray-700 font-bold py-2 px-6 rounded-md">
                Fermer
            </button>
        </div>
    )
}

export default ({ pageContext: { allDeals, HttpGatewayApiGateway } }) => {

    const onClickFilterByDateButton = () => {
        alert('Cette fonctionnalitée arrive bientôt ☺')
        typeof window !== "undefined" && (window as any).gtag("event", "button_date_filter")
    }

    return (
        <div className="bg-gray-100 w-full">

        <Helmet>
            <meta charSet="utf-8" />
            <title>iDeal Hotel - Les meilleurs offres du moment</title>
            <meta name="description" content="Les meilleurs deals dans les plus beaux hôtels et hébergement de la Réunion sont ici." />
            <script async={true} defer={true} crossOrigin="anonymous" nonce="W3fWEx6e" src="https://connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v9.0&appId=164397097544647&autoLogAppEvents=1" />
        </Helmet>

        <Popup />
        
        <div className="w-full pl-2 py-4 bg-brand-500 text-3xl font-bolder text-white text-center md:text-left flex flex-row items-center">
            <img src="/logo.png" className="h-8"/>
            <div className="ml-2 font-bold text-2xl"> i<span className="text-3xl">Deal Hotel </span> </div>
        </div>

        <div className="px-1 sm:px-5 pt-2 md:pt-10 w-full">

        <div className="w-full font-bold mb-2 text-2xl text-gray-900 mt-1 px-2">
            <h1> Hôtels pas chers à la Réunion, les meilleurs deals sont ici ! </h1>
        </div>

        <h2 className="text-md mb-5 text-gray-600 px-2"> Nous avons choisi pour vous les plus belles offres du moment dans les plus beaux hébergements de la Réunion. </h2>

        {/* <div className="mb-5 w-full text-center">
            <button onClick={onClickFilterByDateButton} className="bg-brand-200 text-white px-5 flex p-2 rounded-md mx-auto items-center justify-center"> 
                <div className="h-4 w-4 text-white"> <SvgIcons icon={Icons.CalendarSvg}/> </div>
                <div className="pl-2"> Filtrer par dates  </div>
            </button>
        </div>*/}

        <div className="w-full mt-5 md:max-w-screen-md md:mx-auto">
            {allDeals.map((deal, idx) => <DealCard lazyload={idx >= 1} key={deal.key} deal={deal} />)}
        </div>

        </div>

    </div>
    )
}